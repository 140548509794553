/* eslint-disable max-lines */
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { delay, eventChannel } from 'redux-saga'

import { actions as appActions } from '../app/redux'
import { selectors as authSelectors } from '../auth/redux'
import { getMembershipToChannel, getPubNubInstance, getPubnubIssuesStatus, getUuid, reconnect, setMembershipToChannel } from '../../helpers/PubNubHelpers'
import ChatQueries from '../../graphql/query/Chat'
import ApiSagas from '../api/sagas'

import { actions, selectors } from './redux'


export default class ChatSagas {

  static* updateChatSubscription() {
    if (process.browser) {
      const isConnected = yield select(authSelectors.isConnected)
      const listen = yield select(selectors.listen)

      if (isConnected && !listen) {
        yield put(actions.listen(true))
      } else if (listen) {
        yield put(actions.listen(false))
      }
    }
  }

  static* onListen() {
    const listen = yield select(selectors.listen)

    if (listen) {
      yield call(ChatSagas.openChatSubscription)
    } else {
      yield call(ChatSagas.closeChatSubscription)
    }
  }

  // eslint-disable-next-line complexity
  static* openChatSubscription() {
    const user = yield select(authSelectors.user)
    const uuid = getUuid(user)
    const pubnub = getPubNubInstance(uuid)

    yield call(ChatSagas.subscribeToAll)

    yield call(ChatSagas.refreshUnreadCount)

    const channel = eventChannel((emitter) => {
      const listener = {
        status(status) {
          emitter({ type: 'status', value: status })
        },
        message(m) {
          emitter({ type: 'message', value: m })
        },
        objects(o) {
          emitter({ type: 'objects', value: o })
        },
      }

      pubnub.addListener(listener)

      return () => {
        pubnub.removeListener(listener)
      }
    })

    while (true) {
      const event = yield take(channel)

      if (event) {
        if (event.type === 'message') {
          const currentChannel = yield select(selectors.currentChannel)

          if (currentChannel === event.value?.channel || uuid === event.value?.publisher) {
            yield call(setMembershipToChannel, pubnub, uuid, currentChannel)
            return
          }
        } else if (event.type === 'status') {
          yield put(actions.setPnStatus(event.value?.category))
          if (getPubnubIssuesStatus().includes(event.value?.category)) {
            yield call(reconnect, pubnub)
          }
        }
        yield call(delay, 500)
        yield call(ChatSagas.refreshUnreadCount)
      }
    }
  }


  static* subscribeToAll() {
    const user = yield select(authSelectors.user)
    const uuid = getUuid(user)
    const pubnub = getPubNubInstance(uuid)

    yield call(pubnub?.unsubscribeAll)
    const userMembership = yield call(getMembershipToChannel, pubnub, uuid)

    const channels = []
    const channelTimetokens = []

    if (userMembership?.data) {
      userMembership.data.forEach((entry) => {
        if (entry?.channel?.id && entry?.custom?.lastReadTimetoken) {
          channels.push(entry?.channel?.id)
          channelTimetokens.push(entry?.custom?.lastReadTimetoken)
        }
      })

      if (channels.length > 0) {
        yield call(pubnub.channelGroups.addChannels, {
          channels,
          channelGroup: uuid,
        })
      }

      yield call(pubnub.subscribe, {
        channelGroups: [uuid],
      })
    }
  }

  static* onCurrentChannelChange() {
    const user = yield select(authSelectors.user)

    if (user) {
      const uuid = getUuid(user)
      const pubnub = getPubNubInstance(uuid)
      const currentChannel = yield select(selectors.currentChannel)


      if (uuid && pubnub) {
        if (currentChannel) {
          yield call(setMembershipToChannel, pubnub, uuid, currentChannel)
        } else {
          yield call(delay, 500)
          yield call(ChatSagas.subscribeToAll)
        }
        yield call(ChatSagas.refreshUnreadCount)
      }
    }
  }

  static* refreshUnreadCount() {
    const user = yield select(authSelectors.user)
    const uuid = getUuid(user)
    const pubnub = getPubNubInstance(uuid)
    const userMembership = yield call(getMembershipToChannel, pubnub, uuid)

    const channels = []
    const channelTimetokens = []

    if (userMembership?.data) {
      userMembership.data.forEach((entry) => {
        if (entry?.channel?.id && entry?.custom?.lastReadTimetoken) {
          channels.push(entry?.channel?.id)
          channelTimetokens.push(entry?.custom?.lastReadTimetoken)
        }
      })

      if (channels.length > 0) {
        const data = yield call(pubnub.messageCounts, {
          channels,
          channelTimetokens,
        })

        const count = Object.values(data?.channels)?.reduce((a, b) => a + b, 0)

        yield put(actions.setUnreadMessagesCount(count))
      }
    }
  }

  static* closeChatSubscription() {
    const user = yield select(authSelectors.user)
    const uuid = getUuid(user)
    const pubnub = getPubNubInstance(uuid)

    if (pubnub?.unsubscribeAll) {
      pubnub.unsubscribeAll()
    }
  }

  static* sendNotificationMessage({ payload }) {
    const { channelID, userID, message, fileName } = payload.data

    let idTo

    if (channelID && !channelID.includes('offer')) {
      idTo = channelID.split('_').find((id) => id !== userID && (id) !== 'channel')
    } else if (channelID && channelID.includes('offer')) {
      idTo = channelID.split('admin')[1]
    }

    const result = yield call(ApiSagas.query, ChatQueries.newChatMessage, { to: idTo, channelId: channelID, message, fileName })

    if (result.errors && result.errors.length) {
      console.log(result.errors)
    }
  }

  static* loop() {
    yield all([
      takeLatest(actions.setCurrentChannel.getType(), ChatSagas.onCurrentChannelChange),
      takeLatest(actions.listen.getType(), ChatSagas.onListen),
      takeLatest(appActions.mount.getType(), ChatSagas.updateChatSubscription),
      takeEvery(actions.sendNotification.getType(), ChatSagas.sendNotificationMessage),
    ])
  }

}
